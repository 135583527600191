import { Link } from "gatsby"
import React from "react"
import { agendaData } from "../data/agenda/agenda-data"

export default function IESGNetworkLaunch() {
  return (
    <>
      <div className="mt-5 pt-5 mb-5 px-5 container-custom">
        <h1 className="heading mb-5"> INDIAN ESG NETWORK LAUNCH</h1>
        <div className="comman-para" align="justify">
          The Indian ESG Network was launched, by the Founder Mr. Vikrant Abrol,
          in March 2023 with the objective to ideate and create an ESG
          implementation support network. The event had participation from
          esteemed government organisations, representatives from various
          Corporates/MSME, Social Organisations and Individuals from varied
          backgrounds to support and join the Indian ESG network.
        </div>
        <div className="row justify-content-center">
          {agendaData.map(data => {
            return (
              <>
                <div className="col-md-4 mt-5">
                  <div class="card border-0">
                    <h4 style={{ fontWeight: "bold" }} className="mt-4 mb-4">
                      {data.text}
                    </h4>
                    <img src={data.image} class="w-75 mb-3" alt={``} />
                    <p class="card-text">
                      <ul>
                        {data.speaker.map(speakerData => {
                          return (
                            <>
                              {data.speaker.length === 1 ? (
                                <div>{speakerData}</div>
                              ) : (
                                <li className="ms-3">{speakerData}</li>
                              )}
                            </>
                          )
                        })}
                      </ul>
                    </p>
                    <div className="mt-3">{data.script}</div>
                    <Link to={"/using-dsg" + "?id=" + data?.agendaIndex}>
                      {data.readMore}
                    </Link>

                    {data.youTubeUrl ? (
                      <a
                        href={data?.youTubeUrl}
                        target="_blank"
                        className=" my-3"
                      >
                        Click here for the Video
                      </a>
                    ) : (
                      ""
                    )}

                    <Link to={"/using-dsg" + "?id=" + data?.agendaIndex}>
                      <button className="btn btn-success mt-4 mb-5">
                        More Photos
                      </button>
                    </Link>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}
