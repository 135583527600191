import Inauguration from "../../images/16thMarch2023Event/images/agenda/vikrant3.jpg"
import ramanSharma from "../../images/16thMarch2023Event/images/agenda/ramanSharma.jpg"
import sanjayRakesh from "../../images/16thMarch2023Event/images/agenda/sanjayRakesh2.jpg"
import anjalPrakash from "../../images/16thMarch2023Event/images/agenda/anjalPrakash1.jpg"
import arindamLehri from "../../images/16thMarch2023Event/images/agenda/arindamLehri.jpg"
import aayushiJauhri from "../../images/16thMarch2023Event/images/agenda/aayushi.jpg"
import AnilPokhriyal from "../../images/16thMarch2023Event/images/agenda/anilPokhriyal8.jpg"
import audience from "../../images/16thMarch2023Event/images/agenda/audience.jpg"
import audience1 from "../../images/16thMarch2023Event/images/agenda/audience1.jpg"
import anuMehta2 from "../../images/16thMarch2023Event/images/agenda/anuMehta2.jpg"
import chandrasekar1 from "../../images/16thMarch2023Event/images/agenda/chandrasekar1.jpg"
import shruteeGanguly4 from "../../images/16thMarch2023Event/images/agenda/shruteeGanguly4.jpg"
import anilPokhriyal4 from "../../images/16thMarch2023Event/images/agenda/anilPokhriyal4.jpg"
export const agendaData = [
  {
    text: "Inauguration & Welcome Note",
    speaker: ["Mr. Vikrant Abrol Founder, Indian ESG Network"],
    image: Inauguration,
    agendaIndex: 1,
    script: "India is rapidly becoming the leading country in many areas...",
    readMore: "Read More",
    youTubeUrl:
      "https://www.youtube.com/watch?v=qccJZVLWWwg&ab_channel=IndianESGNetwork",
  },
  {
    text: "Launch of Indian ESG Network & Logo release",
    speaker: ["Chief Guest & Other Dignitaries"],
    majorPoints: [],
    image: audience,
    agendaIndex: 2,
    youTubeUrl: "",
  },
  {
    text: "ESG (Indian ancient wisdom perspective)",
    speaker: ["Mr. Raman Sharma, Ex Director, Honda Cars India Ltd"],
    image: ramanSharma,
    script: "The Earth, as well as all living and nonliving organisms...",
    agendaIndex: 3,
    readMore: "Read More",
    youTubeUrl:
      "https://www.youtube.com/watch?v=BquclzKA5QU&t=378s&ab_channel=IndianESGNetwork",
  },
  {
    text: "The Power of ESG: Empowering Local Communities",
    speaker: ["Mr. Sanjay Rakesh, MD & CEO, Common Service Centres"],
    script: "The Power of ESG: Empowering Local Communities...",
    image: sanjayRakesh,
    agendaIndex: 4,
    readMore: "Read More",
    youTubeUrl: "",
  },
  {
    text: "Advancing Learning Perspectives in the ESG Ecosystem",
    speaker: ["Dr. Anjal Prakash Research Director, Indian School of Business"],
    script: "How organisations and corporations might transition...",
    image: anjalPrakash,
    agendaIndex: 5,
    readMore: "Read More",
    youTubeUrl:
      "https://www.youtube.com/watch?v=QDSqOpxrMwg&t=346s&ab_channel=IndianESGNetwork",
  },
  {
    text: "Chief Guest Address",
    speaker: [
      "Hon. Chief Guest, Shri. Bhagwanth Khuba (Minister of New and Renewable Energy)",
    ],
    majorPoints: [],
    image: audience1,
    agendaIndex: 6,
    youTubeUrl: "",
  },
  {
    text: "Driving Towards Sustainability: The Automobile Industry & Large Corporate Perspective",
    speaker: ["Mr. Arindam Lahiri Chief Executive Officer, ASDC"],
    script:
      "It is critical that we change or limit our transportation services...",
    image: arindamLehri,
    agendaIndex: 7,
    readMore: "Read More",
    youTubeUrl:
      "https://www.youtube.com/watch?v=ZoF2y79w1lk&t=7s&ab_channel=IndianESGNetwork",
  },
  {
    text: "Indian ESG Awards 2022-2023",
    speaker: ["Ms. Aayushi Jauhari Lead, Indian ESG Network"],
    majorPoints: [],
    image: aayushiJauhri,
    agendaIndex: 8,
    youTubeUrl: "",
  },
  {
    text: "ESG Conscious March for People, Planet and Profit - The Journey from India@75 to India@100",
    speaker: [
      "Col. Anil Kumar Pokhriyal CEO, MEPSC",
      " Mr. Amar Variawa, Country Director Michelin",
      "Mr. Gaurav Sarup, Director - ESG, Carbon & Social Performance at Vedanta Resources Limited",
      "Mr. Sunil Wadhwa, Ex MD, Tata Power Delhi Dist. Ltd. (TPDDL), Founder CEO, Multiple Orbits Consulting",
      "Ms. Kanta Singh, Deputy Country, Representative - UN Women India",
    ],
    script:
      "The Indian ESG Network hosted a distinguished panel of experts at the launch event.....",
    image: AnilPokhriyal,
    agendaIndex: 9,
    readMore: "Read More",
    youTubeUrl:
      "https://www.youtube.com/watch?v=blvkIsIyBEw&t=35s&ab_channel=IndianESGNetwork",
  },
  {
    text: "ESG for Everyone - Large Corporates, MSMEs, Social Enterprises and Not For Profits",
    speaker: [
      "Mr. Sachin Golwalkar, CEO, United Way Delhi",
      "Mr. Rajiv Gupta CEO - Tricolite Electrical Industries",
      "Ms. Anu Mehta, Head - CSR at Honda Cars India Ltd",
      "Dr. Sanjay Kumar, President, Harvard Club of India Founder, Edjustice Foundation",
      "Mr. Ravi Sreedharan, Founder, Indian School of Development Management",
    ],
    script: "Importance of adopting a diverse lens to ESG and involving...",
    image: anuMehta2,
    agendaIndex: 10,
    youTubeUrl:
      "https://www.youtube.com/watch?v=LC29Rqe6bYI&t=70s&ab_channel=IndianESGNetwork",
  },
  {
    text: "Capacity Building for ESG - Primary, Secondary and Tertiary.",

    speaker: [
      "Dr. Aarushi Jain Policy Director, Bharti Institute of Public Policy, Indian School of Business",
      "Dr. Chandrasekar Executive Director EdCIL Ministry of Education, Govt. of India",
      "Ms. Anu Prasad Founder & CEO, India Leaders for Social Sector",
      "Dr. Srikant Mohapatra, Pro-Vice Chancellor, IGNOU",
      "Ms. Monika Halan, Chairperson SEBI Advisory Committee for IPEF",
    ],
    majorPoints: [],
    image: chandrasekar1,
    agendaIndex: 11,
    youTubeUrl: "",
  },
  {
    text: "ESG Impact Assessments - Measuring Achievements, Celebrating & Scaling",
    speaker: [
      "Mr. Arindam Lahiri, Chief Executive Officer, ASDC",
      "Prof. Anjal Prakash, Research Director, Indian School of Business",
      "Ms. Shrutee Ganguly, Principal, Corporate Advisory - ESG & Sustainability, Sattva Consulting",
      "Peter Immanuel, Founder & CEO Pepekash Consulting LLP, ESG Expert, Co-Founder & People Strategy Mentor ECO AVIATION PVT. LTD",
    ],
    majorPoints: [],
    image: shruteeGanguly4,
    agendaIndex: 12,
    youTubeUrl: "",
  },
  {
    text: "Vote of Thanks and Closing Ceremony",
    speaker: [
      "Col. Anil Kumar Pokhriyal, CEO, Management & Entrepreneurship and Professional Skills Council (MEPSC)",
    ],
    majorPoints: [],
    image: anilPokhriyal4,
    agendaIndex: 13,
    youTubeUrl: "",
  },
]
